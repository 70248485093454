import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import workData from "@json/work.json"

export const HeroImage = ({ client }) => {
  const data = useStaticQuery(query)
  const { background, backgroundBefore, backgroundAfter } = workData[client].theme

  return (
    <section className="overflow-hidden">
      <div className="container relative">
        <div className="absolute inset-x-0">
          <div
            className={`aspect-w-326 aspect-h-396 sm:aspect-w-667 sm:aspect-h-325 lg:aspect-w-1436 lg:aspect-h-243 extend ${background} ${backgroundBefore} ${backgroundAfter}`}
          ></div>
        </div>
      </div>
      <div className="w-full">
        <div className="container flex pr-0 xl:w-extended-11/12 xl:ml-auto xl:mr-0">
          <div className="w-full ml-auto sm:w-11/12 xl:w-full">
            <div className="aspect-w-351 aspect-h-590 md:aspect-w-605 md:aspect-h-778 lg:aspect-w-1557 lg:aspect-h-778">
              <div data-aos="slide-up" data-aos-delay="400" data-aos-duration="1000">
                <div className="image-fill display-desktop">
                  <GatsbyImage
                    objectFit="contain"
                    loading="eager"
                    image={getImage(data[`${client}Desktop`])}
                    alt={client}
                  />
                </div>
                <div className="image-fill display-tablet">
                  <GatsbyImage objectFit="contain" image={getImage(data[`${client}Tablet`])} alt={client} />
                </div>
                <div className="image-fill display-mobile">
                  <GatsbyImage objectFit="cover" image={getImage(data[`${client}Mobile`])} alt={client} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query {
    ansDesktop: file(relativePath: { eq: "work/ans/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansTablet: file(relativePath: { eq: "work/ans/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansMobile: file(relativePath: { eq: "work/ans/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adDesktop: file(relativePath: { eq: "work/ad/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adTablet: file(relativePath: { eq: "work/ad/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adMobile: file(relativePath: { eq: "work/ad/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmDesktop: file(relativePath: { eq: "work/bpm/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmTablet: file(relativePath: { eq: "work/bpm/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmMobile: file(relativePath: { eq: "work/bpm/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenDesktop: file(relativePath: { eq: "work/braen-stone/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenTablet: file(relativePath: { eq: "work/braen-stone/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braenMobile: file(relativePath: { eq: "work/braen-stone/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrensDesktop: file(relativePath: { eq: "work/childrens/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrensTablet: file(relativePath: { eq: "work/childrens/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrensMobile: file(relativePath: { eq: "work/childrens/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiDesktop: file(relativePath: { eq: "work/lumendi/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiTablet: file(relativePath: { eq: "work/lumendi/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiMobile: file(relativePath: { eq: "work/lumendi/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosDesktop: file(relativePath: { eq: "work/marcos/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosTablet: file(relativePath: { eq: "work/marcos/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosMobile: file(relativePath: { eq: "work/marcos/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrDesktop: file(relativePath: { eq: "work/marcos-csr/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrTablet: file(relativePath: { eq: "work/marcos-csr/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsrMobile: file(relativePath: { eq: "work/marcos-csr/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohDesktop: file(relativePath: { eq: "work/ricoh/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohTablet: file(relativePath: { eq: "work/ricoh/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohMobile: file(relativePath: { eq: "work/ricoh/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbDesktop: file(relativePath: { eq: "work/wb/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbTablet: file(relativePath: { eq: "work/wb/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbMobile: file(relativePath: { eq: "work/wb/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertDesktop: file(relativePath: { eq: "work/weichert/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertTablet: file(relativePath: { eq: "work/weichert/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertMobile: file(relativePath: { eq: "work/weichert/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    xcelisDesktop: file(relativePath: { eq: "work/xcelis/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    xcelisTablet: file(relativePath: { eq: "work/xcelis/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    xcelisMobile: file(relativePath: { eq: "work/xcelis/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
