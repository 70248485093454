import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import workData from "@json/work.json"

export const Featured = ({ client, delay = false }) => {
  const { slug, name, headline } = workData[client]
  const images = useStaticQuery(query)

  return (
    <div data-aos="fade-up">
      <Link to={`/work/${slug}`} className="block aspect-w-468 aspect-h-600 zoom">
        <div className="inset-0 zoom-image">
          <GatsbyImage loading="eager" image={getImage(images[client])} alt={name} />
        </div>
      </Link>
      <div className="w-full mt-clamp-8-15 pr-14">
        <h2 className="text-32">{name}</h2>
        <p className="mt-6 font-body">{headline}</p>
      </div>
    </div>
  )
}

Featured.propTypes = {
  client: PropTypes.string.isRequired,
  delay: PropTypes.bool,
}

const query = graphql`
  query {
    ad: file(relativePath: { eq: "work/ad/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ans: file(relativePath: { eq: "work/ans/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpm: file(relativePath: { eq: "work/bpm/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    braen: file(relativePath: { eq: "work/braen-stone/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    childrens: file(relativePath: { eq: "work/childrens/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendi: file(relativePath: { eq: "work/lumendi/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcos: file(relativePath: { eq: "work/marcos/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcoscsr: file(relativePath: { eq: "work/marcos-csr/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricoh: file(relativePath: { eq: "work/ricoh/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wb: file(relativePath: { eq: "work/wb/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichert: file(relativePath: { eq: "work/weichert/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    xcelis: file(relativePath: { eq: "work/xcelis/featured.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
